import { Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HeaderMenuLinksSubmenu from '~/base/components/Header/HeaderMenuLinksSubmenu';
import { MenuLinkType } from '~/base/types/MenuLinkType';

interface HeaderMenuItemsProps {
  menuLinks: Array<MenuLinkType>;
  handleItemClick: (url: string) => void;
}

function HeaderMenuLinksDefault({
  handleItemClick,
  menuLinks,
}: HeaderMenuItemsProps) {
  const { t } = useTranslation(['translation']);

  const HEADER_LINK_AND_ITEM_SX = {
    display: { xs: 'block', lg: 'flex' },
    fontFamily: 'National2Bold',
    fontWeight: 700,
    fontSize: '1rem',
    color: '#FFFFFF',
    underline: 'hover',
    textDecorationThickness: '.125rem',
    textUnderlineOffset: '.45rem',
    textAlign: 'center',
    textDecorationColor: '#000',
    '&:hover': {
      textDecorationColor: '#fff',
    },
  };

  return (
    <>
      {menuLinks.map(
        ({
          textOrComponent: itemTextOrComponent,
          url,
          menuItems,
          type,
          index,
        }: MenuLinkType) => {
          let headerItem;
          if (type === 'link' && url !== undefined) {
            headerItem = (
              <Link
                sx={HEADER_LINK_AND_ITEM_SX}
                href={url}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleItemClick(url);
                }}
              >
                {typeof itemTextOrComponent === 'string'
                  ? t(itemTextOrComponent)
                  : itemTextOrComponent}
              </Link>
            );
          }
          if (type === 'menu' && menuItems !== undefined) {
            headerItem = (
              <HeaderMenuLinksSubmenu
                textOrComponent={itemTextOrComponent}
                menuItems={menuItems}
                handleItemClick={handleItemClick}
              />
            );
          }

          return (
            <Grid
              item
              className="header-item"
              sx={HEADER_LINK_AND_ITEM_SX}
              key={index}
            >
              {headerItem}
            </Grid>
          );
        },
      )}
    </>
  );
}

export default HeaderMenuLinksDefault;
