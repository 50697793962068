import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { T } from '~/types/Translator';
import YoutubeAllowlistedModal from '../YoutubeAllowlisted';

export default function YoutubeAllowlistPrompt({
  allowlisted = false,
  t,
}: {
  allowlisted: boolean;
  t: T;
}) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'divider',
        borderRadius: 2,
        display: 'grid',
        p: 1,
      }}
    >
      <Typography variant="h6">{t('page.allowlisted.title')}</Typography>
      <Typography>{t('page.allowlisted.copy')}</Typography>
      <Button
        color="info"
        variant="contained"
        sx={{ width: 'min-content', justifySelf: 'end', m: 1 }}
        onClick={() => navigate('/youtube/allowlisted')}
      >
        {t('page.allowlisted.buttons.open')}
      </Button>
      {allowlisted && <YoutubeAllowlistedModal open={allowlisted} t={t} />}
    </Box>
  );
}
