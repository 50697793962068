import { useReactiveVar } from '@apollo/client';
import { Grid } from '@mui/material';
import HeaderButtons from '~/base/components/Header/HeaderButtons';
import HeaderLogo from '~/base/components/Header/HeaderLogo';
import HeaderMenuLinks from '~/base/components/Header/HeaderMenuLinks';
import HeaderSlideDownMenu from '~/base/components/Header/HeaderSlideDownMenu';
import { LOGGED_IN_HEADER_LINKS } from '~/base/constants/headerConstants';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import SwitchNameEnum from '~/base/enums/switchNameEnum';
import useFeatureFlag from '~/base/hooks/useFeatureFlag';
import { type MenuLinkType } from '~/base/types/MenuLinkType';
import {
  currentSessionTokenVar,
  currentSongtrustUserPersonaVar,
} from '~/cache';

function HeaderInternal() {
  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const loggedInUserPersona = useReactiveVar(currentSongtrustUserPersonaVar);

  const userIsActive =
    loggedInUserPersona?.loggedInSongtrustUser?.user?.isActive;

  // Map the header links for the logged-in user
  const loggedInHeaderLinks: MenuLinkType[] = LOGGED_IN_HEADER_LINKS.slice();

  // Check if MFA switch is on and replace the menu item link if so
  const {
    hasFlag: mfaSwitch,
    data: mfaData,
    error: mfaError,
  } = useFeatureFlag(SwitchNameEnum.MFA, FlagTypeEnum.SWITCH);

  if (!mfaError && mfaData && mfaSwitch) {
    const securityLink = {
      textOrComponent: 'header.internal.security',
      url: '/account/security',
      type: 'link',
      index: 1,
    };

    const accountSettingsMenu = loggedInHeaderLinks.find(
      (item) => item.textOrComponent === 'header.internal.account-settings',
    );

    if (accountSettingsMenu?.menuItems) {
      const changePasswordIndex = accountSettingsMenu.menuItems.findIndex(
        (item) => item.textOrComponent === 'header.internal.change-password',
      );

      if (changePasswordIndex !== -1) {
        accountSettingsMenu.menuItems[changePasswordIndex] = securityLink;
      }
    }
  }

  const prunedMenuLinksForInactiveUser: MenuLinkType[] = !userIsActive
    ? loggedInHeaderLinks.filter(
        (item: MenuLinkType): boolean =>
          (loggedInUserPersona ?? false) && !item.hideWhenInactive,
      )
    : loggedInHeaderLinks;

  const prunedMenuLinksNonMobile: MenuLinkType[] =
    prunedMenuLinksForInactiveUser.filter(
      (item: MenuLinkType): boolean => item.hideWhenNotMobile !== true,
    );

  return (
    <Grid
      container
      data-testid="header-internal"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: '4rem' }}
    >
      <Grid item data-testid="header-internal-left">
        <Grid
          container
          data-testid="internal-header-left-mobile"
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          <HeaderSlideDownMenu menuLinks={prunedMenuLinksForInactiveUser} />
        </Grid>

        <Grid
          container
          data-testid="header-internal-left-desktop"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          <Grid item>
            <HeaderLogo size={sessionToken ? 'sm' : 'lg'} />
          </Grid>

          <Grid item>
            <Grid
              container
              data-testid="header-internal-left-mobile"
              spacing={2}
              alignItems="center"
              flexGrow={1}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <HeaderMenuLinks menuLinks={prunedMenuLinksNonMobile} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        data-testid="header-internal-center"
        sx={{ display: { xs: 'flex', md: 'none' } }}
      >
        <HeaderLogo size="md" />
      </Grid>

      <Grid item data-testid="header-internal-right">
        <Grid
          container
          data-testid="header-internal-right-buttons"
          spacing={2}
          alignItems="center"
          flexGrow={1}
        >
          <HeaderButtons />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderInternal;
