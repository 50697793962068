import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import FormInput from '~/base/components/FormInput';
import FormSelect from '~/base/components/FormSelect';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import languageOptions from '~/constants/languages';
import { Translator } from '~/types/Translator';

export default function Lyrics({ t }: Translator) {
  const { getValues } = useFormContext();

  const language = getValues('language');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionTitle>{t('form.section.lyrics.title')}</SectionTitle>
      </Grid>
      <Grid container item xs={12} rowGap={2}>
        <FormSelect
          id="lyrics-language"
          label={t('form.labels.language')}
          name="language"
          options={languageOptions}
          sx={{ width: '100%' }}
          value={((language as string) || 'en').toLowerCase()}
          noBlur
        />

        <FormInput
          id="full-lyrics"
          label={t(`form.section.lyrics.full-lyrics`)}
          name="fullLyrics"
          sx={{ width: '100%' }}
          rows={4}
          multiline
          inputFullWidth
        />
        <FormInput
          id="chorus-lyrics"
          label={t(`form.section.lyrics.chorus-lyrics`)}
          name="chorusLyrics"
          sx={{ width: '100%' }}
          rows={4}
          multiline
          inputFullWidth
        />
      </Grid>
    </Grid>
  );
}
