import { BasicMenuType } from '~/base/types/MenuLinkType';

import HeaderMenuLinksSubmenuDesktop from '~/base/components/Header/HeaderMenuLinksSubmenu/HeaderMenuLinksSubmenuDesktop';
import HeaderMenuLinksSubmenuMobile from '~/base/components/Header/HeaderMenuLinksSubmenu/HeaderMenuLinksSubmenuMobile';

function HeaderMenuLinksSubmenu({
  textOrComponent,
  menuItems,
  handleItemClick,
}: BasicMenuType) {
  return (
    <div className="basic-menu" data-testid="basic-menu">
      <HeaderMenuLinksSubmenuMobile
        textOrComponent={textOrComponent}
        menuItems={menuItems}
        handleItemClick={handleItemClick}
      />
      <HeaderMenuLinksSubmenuDesktop
        textOrComponent={textOrComponent}
        menuItems={menuItems}
        handleItemClick={handleItemClick}
      />
    </div>
  );
}
export default HeaderMenuLinksSubmenu;
