import { useTranslation } from 'react-i18next';
import FlagNameEnum from '~/base/enums/flagNameEnum';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import FlagCheckedChildren from '../../FlagCheckedChildren';
import HeaderNoticeBar from '../HeaderNoticeBar';

interface HeaderBannersProps {
  isUserLoggedIn: boolean;
  shouldDisplayTaxBanner: boolean;
}

function HeaderBanners({
  isUserLoggedIn = false,
  shouldDisplayTaxBanner = false,
}: HeaderBannersProps): JSX.Element {
  const { t } = useTranslation(['translation']);

  const banners = [
    {
      name: FlagNameEnum.SHOW_NOTICE_BANNER,
      flagType: FlagTypeEnum.FLAG,
      color: '#52bcd6',
      fontColor: '#000',
      buttonColor: '#89ff9a',
      fontSize: '1.125rem',
      message: t('notice.notice-banner.notice-text'),
      buttonLabel: t('notice.notice-banner.notice-button'),
      buttonLink: t('notice.notice-banner.notice-link'),
      enabled: isUserLoggedIn,
    },
  ];

  return (
    <>
      {banners
        .filter((x) => x.enabled)
        .map(
          ({
            name,
            color,
            fontColor,
            buttonColor,
            fontSize,
            message,
            buttonLabel,
            buttonLink,
            flagType,
          }) => (
            <FlagCheckedChildren
              key={name}
              name={name}
              type={flagType}
              // eslint-disable-next-line react/jsx-no-useless-fragment
              alternative={<></>}
              hideLoadingIndicator
            >
              <HeaderNoticeBar
                color={color}
                fontColor={fontColor}
                buttonColor={buttonColor}
                fontSize={fontSize}
                message={message}
                hasButton
                buttonLabel={buttonLabel}
                buttonLink={buttonLink}
              />
            </FlagCheckedChildren>
          ),
        )}

      {shouldDisplayTaxBanner && (
        <HeaderNoticeBar
          color="#F2F2F2"
          buttonColor="#fff"
          fontColor="#000"
          fontSize="0.875rem"
          message={t('notice.tax-banner.notice-text')}
          hasButton
          buttonLabel={t('notice.tax-banner.notice-button')}
          buttonLink={t('notice.tax-banner.notice-link')}
          target="_self"
        />
      )}
    </>
  );
}

export default HeaderBanners;
