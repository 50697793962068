import { ApolloError } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useRef, useState } from 'react';
import {
  FieldValues,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';

import FormInput from '~/base/components/FormInput';
import FormSelect from '~/base/components/FormSelect';
import { Translator } from '~/types/Translator';
import {
  CreateOutsideSongwriterMutation,
  OutsideSongwriterInput,
  PublishingCompanyInput,
  useCreateOutsideSongwriterMutation,
  usePerformingRightsOrganizationsQuery,
} from '~/types/generated/graphql';

interface AddOutsideSongwriterProps extends Translator {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddOutsideSongwriter({
  t,
  open,
  setOpen,
}: AddOutsideSongwriterProps) {
  /** State */
  const [publisherOpen, setPublisherOpen] = useState(false);
  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');

  /** Hooks */
  const formRef = useRef<HTMLFormElement>(null);
  const formMethods = useForm();

  const {
    fields: alternateNameFields,
    append,
    remove,
  } = useFieldArray({
    name: 'alternateNames',
    control: formMethods.control,
  });

  /** Mutations/Queries */
  const { data: proData } = usePerformingRightsOrganizationsQuery();
  const [createOutsideSongwriter, { loading }] =
    useCreateOutsideSongwriterMutation({
      fetchPolicy: 'no-cache',
    });

  /** Handlers */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Handles errors that occur during form submission.
   * If an error is provided, scrolls the window to the top.
   * @param {ApolloError | undefined} inError The error object, if any, encountered during submission.
   */
  const handleFormError = (data: CreateOutsideSongwriterMutation) => {
    if (data?.createOutsideSongwriter?.errors?.length !== 0) {
      let errorString = '';
      data?.createOutsideSongwriter?.errors?.forEach((error) => {
        errorString += `\n${error?.error}`;
      });
      setProcessError(errorString);
      setWasSaved(false);
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  /**
   * Sets an error message and updates the saved state of the form to false.
   * This function is typically used to handle errors encountered.
   */
  const handleSubmitError = (inError: ApolloError | undefined) => {
    if (inError) {
      setProcessError(inError.message);
      setWasSaved(false);
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  /**
   * Handles the completion of form submission.
   * If there are errors in the submission data, processes them using handleFormError.
   * Otherwise, sets the form state to indicate successful saving and navigates to '/songwriters/manage'.
   * @param {CreateOutsideSongwriterMutation} data The result data from the createSongwriter mutation.
   */
  const handleSubmitComplete = (data: CreateOutsideSongwriterMutation) => {
    if (data?.createOutsideSongwriter?.errors?.length !== 0) {
      handleFormError(data);
    } else {
      setWasSaved(true);
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  };

  /**
   * Handles form submission asynchronously.
   * If publisherOpen is false, hides the publishingCompany field in the form values.
   * Calls createSongwriter mutation with the modified variables.
   * Logs any submission error to the console.
   * @param {FieldValues} values The form values containing input data.
   */
  const handleSubmit = async (values: FieldValues) => {
    const publishingCompanyInput: PublishingCompanyInput = {
      name: values['songwriter-publisher-name'],
      ipi: values['songwriter-publisher-ipi'],
      email: values['songwriter-publisher-email'],
    };

    const proInput = {
      name: values.pro,
    };

    const songwriterInput: OutsideSongwriterInput = {
      songwriterType: 'outside',
      email: values.email.length > 0 ? values.email : undefined,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      alternateNames: values.alternateNames
        .filter((obj: { name: string }) => obj?.name.trim().length > 0)
        .map((obj: { name: string }) => obj.name.trim()),
      pro: proInput.name ? proInput : undefined,
      ipi: values.ipi.length > 0 ? values.ipi : undefined,
      songwriterYoutubeClaims: true, // This is an OPT OUT of youtube claims. So should be TRUE for all OutsideSongwriters, probably needs a better name.
      publishingCompany: publisherOpen ? publishingCompanyInput : undefined,
    };

    /** Submit mutation */
    await createOutsideSongwriter({
      variables: {
        input: songwriterInput,
      },
      onCompleted: handleSubmitComplete,
      onError: handleSubmitError,
    });
  };

  /** Display */
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  /** Render */
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullScreen={!md}
      fullWidth
    >
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault(); // Prevent default form submission
          e.stopPropagation(); // Stop event from bubbling and attempting to submit parent Add Song form
          setProcessError(''); // Clear any previous errors from prior submission attempt
          formMethods.handleSubmit(handleSubmit)();
        }}
      >
        <FormProvider {...formMethods}>
          <DialogContent>
            {/* Title */}
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="h2" data-testid="dialog-title">
                {t('form.titles.modal')}
              </Typography>
            </Grid>

            {/* Success/Error banner */}
            <Grid item xs={12}>
              {!wasSaved && (
                <FormBanner text={processError} type={FormBannerType.ERROR} />
              )}
              {wasSaved && (
                <FormBanner
                  text={t('form.success.outside-songwriter-added')}
                  type={FormBannerType.SUCCESS}
                />
              )}
            </Grid>

            {/* Name Subsection */}
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="h4">
                {t('form.labels.modalNameSection')}
              </Typography>
            </Grid>
            <Grid container spacing={1} sx={{ rowGap: 2 }}>
              <Grid item xs={12} md={4}>
                <FormInput
                  id="firstName"
                  name="firstName"
                  label={t('form.labels.modalFirstName')}
                  inputProps={{
                    autoComplete: 'given-name',
                  }}
                  type="text"
                  autocomplete
                  required={{
                    value: true,
                    message: t('form.fieldValidationRequired.firstName'),
                  }}
                  displayRequired
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  id="middleName"
                  name="middleName"
                  label={t('form.labels.modalMiddleName')}
                  inputProps={{
                    autocomplete: 'additional-name',
                  }}
                  type="text"
                  autocomplete
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  id="lastName"
                  name="lastName"
                  label={t('form.labels.modalLastName')}
                  inputProps={{
                    autocomplete: 'family-name',
                  }}
                  type="text"
                  autocomplete
                  required={{
                    value: true,
                    message: t('form.fieldValidationRequired.lastName'),
                  }}
                  displayRequired
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  id="email"
                  name="email"
                  label={t('form.labels.modalEmail')}
                  inputProps={{ autocomplete: 'email' }}
                  type="email"
                  autocomplete
                  sx={{ width: '100%' }}
                />
              </Grid>

              {/* Alternate name */}
              <Grid container item xs={12} gap={2}>
                {alternateNameFields.map((field, i) => {
                  return (
                    <Grid key={field.id} container item direction="row" xs={12}>
                      <FormInput
                        key={field.id}
                        id={`non-songtrust-alternate-name-${field.id}`}
                        name={`alternateNames.${i}.name`}
                        label={t('form.labels.modalAlternateName')}
                        sx={{ flexGrow: '1' }}
                        inputProps={{
                          'data-testid': `outside-alternate-name-${i}`,
                        }}
                      />
                      <IconButton
                        data-testid={`outside-alternate-name-close-${i}`}
                        onClick={() => remove(i)}
                        sx={{ mt: 2 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container item sx={{ mb: 4 }}>
                <Button
                  data-testid="add-alternate-name"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    append('');
                  }}
                >
                  {t('form.buttons.modalAlternateName')}
                </Button>
              </Grid>
            </Grid>

            {/* PRO details subsection */}
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="h4">
                {t('form.labels.modalProSection')}
              </Typography>
            </Grid>
            <Grid container gap={3} direction="column">
              <FormSelect
                id="outside-pro"
                data-testid="outside-pro"
                name="pro"
                label={t('form.labels.outsideSongwriterPRO')}
                options={
                  proData?.performingRightsOrganizations?.edges?.map(
                    (edge) => ({
                      choiceId: edge?.node?.id || '',
                      choiceLabel: edge?.node?.name || '',
                    }),
                  ) || []
                }
                tooltip={t('form.tips.pro')}
                placeholder={t('form.labels.outsideSongwriterPRO')}
                noBlur
              />
              <FormInput
                id="non-songtrust-ipi"
                name="ipi"
                label={t('form.labels.outsideSongwriterIPI')}
                tooltip={t('form.tips.ipi')}
              />
            </Grid>

            {/* Publisher checkbox and details subsection */}
            <Grid item sx={{ pl: 2, pt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="toggle-publisher"
                    onChange={() => setPublisherOpen((prev) => !prev)}
                  />
                }
                label={t('form.labels.modalPublisherCheckbox')}
              />
            </Grid>
            <Collapse in={publisherOpen} collapsedSize={0}>
              <Grid item xs={12} sx={{ mb: 2, pt: 2 }}>
                <Typography variant="h4">
                  {t('form.labels.modalPublisherSection')}
                </Typography>
              </Grid>
              <Grid container item direction="column" xs={12} gap={3}>
                <FormInput
                  id="songwriter-publisher-name"
                  name="songwriter-publisher-name"
                  label={t('form.labels.outsideSongwriterPublisher')}
                  required={{
                    value: publisherOpen,
                    message: t('form.fieldValidationRequired.publisherName'),
                  }}
                  displayRequired
                />
                <FormInput
                  id="songwriter-publisher-ipi"
                  name="songwriter-publisher-ipi"
                  label={t('form.labels.outsideSongwriterPublisherIPI')}
                  required={{
                    value: publisherOpen,
                    message: t('form.fieldValidationRequired.publisherIPI'),
                  }}
                  displayRequired
                />
                <FormInput
                  id="songwriter-publisher-email"
                  name="songwriter-publisher-email"
                  label={t('form.labels.outsideSongwriterPublisherEmail')}
                  type="email"
                />
              </Grid>
            </Collapse>
          </DialogContent>

          {/* Action buttons */}
          <DialogActions
            sx={{
              justifyContent: xs ? 'space-between' : 'flex-end',
            }}
          >
            <Button
              data-testid="close-dialog"
              variant="outlined"
              onClick={handleClose}
            >
              {t('form.buttons.cancel')}
            </Button>
            <Button
              data-testid="submit-outside-songwriter"
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? t('form.buttons.loading') : t('form.buttons.submit')}
            </Button>
          </DialogActions>
        </FormProvider>
      </form>
    </Dialog>
  );
}
