import { useReactiveVar } from '@apollo/client';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HeaderPersonaMenu from '~/base/components/Header/HeaderPersonaMenu';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import SwitchNameEnum from '~/base/enums/switchNameEnum';
import useFeatureFlag from '~/base/hooks/useFeatureFlag';
import {
  currentSessionTokenVar,
  currentSongtrustUserPersonaVar,
} from '~/cache';
import HeaderAppSwitcher from '../HeaderAppSwitcher/HeaderAppSwitcher';

interface HeaderButtonsProps {
  hideJoinSignIn?: boolean;
  hidePersonaMenu?: boolean;
  alwaysShowLogout?: boolean;
}

/**
 * HeaderButtons component renders various buttons in the header based on the user's session status.
 *
 * @param {boolean} [hideJoinSignIn=false] - Flag to hide the Join and Sign In buttons.
 * @param {boolean} [hidePersonaMenu=false] - Flag to hide the Persona Menu.
 * @param {boolean} [alwaysShowLogout=false] - Flag to always show the Logout button on medium screens.
 * @returns {JSX.Element} The rendered buttons component.
 */
function HeaderButtons({
  hideJoinSignIn = false,
  hidePersonaMenu = false,
  alwaysShowLogout = false,
}: HeaderButtonsProps): JSX.Element {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const loggedInUserPersona = useReactiveVar(currentSongtrustUserPersonaVar);

  const userIsB2bClient =
    !!loggedInUserPersona?.loggedInSongtrustUser?.isB2bClient;

  // Check if AppSwitcher switch is on
  const { hasFlag: appSwitcherFlag } = useFeatureFlag(
    SwitchNameEnum.APPSWITCHER,
    FlagTypeEnum.SWITCH,
  );

  /**
   * Handles the click event for the Logout button.
   */
  const handleLogoutClick = () => {
    navigate('/logout');
  };

  /**
   * Handles the click event for the Join button.
   */
  const handleJoinClick = () => {
    navigate('/signup');
  };

  /**
   * Handles the click event for the Sign In button.
   */
  const handleSignInClick = () => {
    navigate('/login');
  };

  return (
    <>
      {!sessionToken && !hideJoinSignIn && (
        <Grid item data-testid="header-button-join">
          <Button
            variant="contained"
            color="secondary"
            sx={{
              backgroundColor: 'white',
              minWidth: '6.5rem',
            }}
            type="button"
            onClick={handleJoinClick}
          >
            {t('header.external.join')}
          </Button>
        </Grid>
      )}

      {!sessionToken && !hideJoinSignIn && (
        <Grid item data-testid="header-button-sign-in">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            sx={{ minWidth: '6.5rem' }}
            onClick={handleSignInClick}
          >
            {t('header.external.sign-in')}
          </Button>
        </Grid>
      )}

      {sessionToken && (
        <Grid item data-testid="header-buttons-logged-in">
          <Grid item container alignItems="center" justifyItems="center">
            <Grid
              item
              data-testid="header-persona"
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <HeaderPersonaMenu isMenuHidden={hidePersonaMenu} />
            </Grid>

            <Grid item data-testid="header-button-logout">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  display: {
                    xs: 'flex',
                    md: alwaysShowLogout ? 'flex' : 'none',
                    lg: 'flex',
                  },
                  minWidth: '6.5rem',
                }}
                onClick={handleLogoutClick}
              >
                {t('header.external.logout')}
              </Button>
            </Grid>
            {appSwitcherFlag && (
              <HeaderAppSwitcher isB2bClient={userIsB2bClient} />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default HeaderButtons;
