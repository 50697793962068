import {
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import usePageTitle from '~/hooks/usePageTitle';
import { SongType } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import SongList from '../SongList/SongList';

function ManageSongsPage({ t }: Translator) {
  usePageTitle(t('page.titles.manageSongs'));
  const navigate = useNavigate();

  const [songTextSearch, setSongTextSearch] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [numberOfSongsAdded, setnumberOfSongsAdded] = useState<number>(0);

  const handleOnLoadCompleted = (res: number) => {
    setnumberOfSongsAdded(res);
  };

  const handleAddSongClick = () => {
    navigate('/songs/add');
  };

  const handleOnCopySplits = async (
    songId: string,
    song: SongType | null | undefined,
  ) => {
    navigate('/songs/add', {
      replace: true,
      state: { copySplitsSongId: Number(songId), song },
    });
  };

  const handleOnAddRecording = async (songId: string) => {
    navigate(`/songs/${songId}/recordings`);
  };

  return (
    <Container data-testid="manage-songs-page">
      <Grid container sx={{ flexDirection: 'column' }} spacing={2}>
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <PageTitle
                pageTitle={{
                  id: 'header-title-add-song',
                  title: t('page.titles.manageSongs'),
                }}
              />
            </Grid>
            <Grid item sx={{ pt: '3rem' }}>
              <Button
                data-testid="add-song-button"
                variant="contained"
                color="secondary"
                onClick={handleAddSongClick}
                sx={{
                  backgroundColor: '#7fc832',
                  px: '2rem',
                  '&:hover': {
                    backgroundColor: '#7fc832',
                  },
                }}
              >
                {t('form.buttons.addSong')}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" data-testid="">
                {t('page.copy.manageSongsContent')}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant="h5"
                  component="span"
                  sx={{
                    fontSize: '1rem',
                    pr: '1rem',
                  }}
                  data-testid="song-added-label"
                >
                  {`${numberOfSongsAdded} ${t('form.labels.songsAdded')}`}
                </Typography>
              </Grid>

              <Grid item>
                <TextField
                  data-testid="song-search-textbox"
                  placeholder={t('form.placeholders.searchSong')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={songTextSearch}
                  onChange={(event) => {
                    setSongTextSearch(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      setSearchTerm(songTextSearch);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SongList
            searchTerm={searchTerm}
            onLoadCompleted={handleOnLoadCompleted}
            onCopySplits={handleOnCopySplits}
            onAddRecording={handleOnAddRecording}
            t={t}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ManageSongsPage;
