import { Button, Grid } from '@mui/material';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '~/base/components/ConfirmationDialog';
import FormBanner, {
  FormBannerType,
} from '~/base/components/FormBanner/FormBanner';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import {
  RecordingType,
  SongType,
  useDeleteRecordingMutation,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import RecordingItem from './RecordingItem';

interface RecordingsListProps extends Translator {
  song: SongType | undefined;
  handleRemoveRecording: (recordingId: number) => void;
}

export default function RecordingsList({
  t,
  song,
  handleRemoveRecording,
}: RecordingsListProps) {
  const navigate = useNavigate();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedRecordingId, setSelectedRecordingId] = useState<number | null>(
    null,
  );
  const [playingRecordingId, setPlayingRecordingId] = useState<string | null>(
    null,
  );
  const [currentTimes, setCurrentTimes] = useState<{ [key: string]: number }>(
    {},
  );
  const [durations, setDurations] = useState<{ [key: string]: number }>({});

  const audioRefs = useRef<{ [key: string]: HTMLAudioElement | null }>({});

  const [deleteRecording] = useDeleteRecordingMutation({
    fetchPolicy: 'no-cache',
  });

  const handlePlayPauseClick = (id: string) => {
    const audioElement = audioRefs.current[id];
    if (audioElement) {
      if (playingRecordingId === id) {
        audioElement.pause();
        setPlayingRecordingId(null);
      } else {
        if (playingRecordingId && audioRefs.current[playingRecordingId]) {
          audioRefs.current[playingRecordingId]?.pause();
        }
        audioElement.play();
        setPlayingRecordingId(id);
      }
    }
  };

  const handleAudioTimeUpdate = (id: string) => {
    const audioElement = audioRefs.current[id];
    if (audioElement) {
      setCurrentTimes((prev) => ({
        ...prev,
        [id]: audioElement.currentTime,
      }));
    }
  };

  const handleAudioLoadedMetadata = (id: string) => {
    const audioElement = audioRefs.current[id];
    if (audioElement) {
      setDurations((prev) => ({
        ...prev,
        [id]: audioElement.duration,
      }));
    }
  };

  const handleSeek = (id: string, event: React.MouseEvent<HTMLDivElement>) => {
    const audioElement = audioRefs.current[id];
    if (!audioElement) return;

    const progressBar = event.currentTarget;
    const clickX = event.nativeEvent.offsetX;
    const barWidth = progressBar.clientWidth;
    const newTime = (clickX / barWidth) * audioElement.duration;

    audioElement.currentTime = newTime;
  };

  const handleAddRecordingClick = () =>
    navigate(`/songs/${song?.id}/recordings`);
  const handleEditRecordingClick = (id: number) =>
    navigate(`/songs/${song?.id}/recordings/${id}`);
  const handleDeleteRecordingClick = (id: number) => {
    setSelectedRecordingId(id);
    setConfirmDialogOpen(true);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleConfirmDialogClose = (confirmed: string) => {
    setConfirmDialogOpen(false);
    if (confirmed === 'yes' && selectedRecordingId !== null) {
      deleteRecording({
        variables: { recordingId: selectedRecordingId },
      });
      handleRemoveRecording(selectedRecordingId);
    }
    setSelectedRecordingId(null);
  };

  const dialogContent = <>{t(`form.warnings.add-recording.copy`)}</>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionTitle>{t('form.section.recordings.title')}</SectionTitle>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {!song && <LoadingIndicator size={50} />}

          {song && (song?.recordings?.totalCount || 0) === 0 && (
            <Grid item xs={12}>
              <FormBanner
                text={t('form.warnings.add-recording.copy')}
                type={FormBannerType.WARNING}
              />
            </Grid>
          )}

          {song?.displayStatus !== 'awaiting_recording' && (
            <>
              <ConfirmationDialog
                dialogContent={dialogContent}
                dialogTitle="Delete Recording"
                handleClose={handleConfirmDialogClose}
                noButtonText={t(`form.section.recordings.deny-delete`)}
                open={confirmDialogOpen}
                yesButtonText={t(`form.section.recordings.confirm-delete`)}
              />

              {song?.recordings?.edges?.map((edge) => {
                if (!edge) return null;

                const { id, name, artists, spotifyPreviewUrl, spotifyTrackId } =
                  edge.node as RecordingType;

                return (
                  <RecordingItem
                    key={id}
                    id={id}
                    name={name}
                    artists={artists || []}
                    spotifyTrackId={spotifyTrackId as string}
                    spotifyPreviewUrl={spotifyPreviewUrl as string}
                    currentTime={currentTimes[id] || 0}
                    duration={durations[id] || 0}
                    playingRecordingId={playingRecordingId}
                    handlePlayPauseClick={handlePlayPauseClick}
                    handleEditRecordingClick={handleEditRecordingClick}
                    handleDeleteRecordingClick={handleDeleteRecordingClick}
                    handleSeek={handleSeek}
                    formatTime={formatTime}
                    audioRef={(el) => {
                      // Assign the audio element to the audioRefs.current object
                      audioRefs.current[id] = el;
                    }}
                    handleAudioTimeUpdate={handleAudioTimeUpdate}
                    handleAudioLoadedMetadata={handleAudioLoadedMetadata}
                    disabled={
                      song?.displayStatusLog?.sent ||
                      song?.displayStatusLog?.registering
                    }
                    t={t}
                  />
                );
              })}
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          id="add-recording-button"
          variant="outlined"
          onClick={handleAddRecordingClick}
          disabled={!song}
        >
          {t(`form.section.recordings.add-recording`)}
        </Button>
      </Grid>
    </Grid>
  );
}
