import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function AddOutsideSongwriter() {
  const { t } = useTranslation('translation');
  return (
    <Box
      sx={{
        lineHeight: 1.2,
      }}
    >
      <div>
        <Typography variant="inherit">
          {t('header.internal.addOutsideSongwriter1')}
        </Typography>
        <Typography variant="caption">
          {t('header.internal.addOutsideSongwriter2')}
        </Typography>
      </div>
    </Box>
  );
}

export default AddOutsideSongwriter;
