import AppsIcon from '@mui/icons-material/Apps';
import { Grid, Popover } from '@mui/material';
import { useEffect, useState } from 'react';

function HeaderButtons({ isB2bClient }: { isB2bClient: boolean }) {
  const viewConfig = isB2bClient ? 'songtrust_business' : 'songtrust_creator';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'app-switcher-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== 'https://downtownmusic.com') return;
      if (event.data.type === 'appSwitcherLinkClicked') {
        setAnchorEl(null);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Grid item data-testid="header-button-app-switcher" onClick={handleClick}>
      <AppsIcon
        sx={{
          fontSize: '2.0625rem',
          color: '#FFFFFF',
          display: 'flex',
          alignSelf: 'center',
          marginLeft: '0.625rem',
          cursor: 'pointer',
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <iframe
          src={`https://downtownmusic.com/appswitcher?sourceApp=${viewConfig}`}
          width="400"
          height="315"
          title="App Switcher"
        />
      </Popover>
    </Grid>
  );
}

export default HeaderButtons;
