import { useReactiveVar } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { MfaFormValues } from '~/account/types/types';
import { getMaskedPhoneNumber } from '~/base/utils/mfaUtils';
import { currentSongtrustUserPersonaVar } from '~/cache';
import usePageTitle from '~/hooks/usePageTitle';
import { ChannelEnum } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import MfaFormStepOne from './MfaFormStepOne';
import MfaFormStepTwo from './MfaFormStepTwo';

function MfaPage({ t }: Translator): JSX.Element {
  usePageTitle(t('sections.security.title'));
  const location = useLocation();
  const navigate = useNavigate();
  const userOptingIn = location.pathname.includes('opt-in-mfa');
  const [mfaStep, setMfaStep] = useState<number>(userOptingIn ? 1 : 2);
  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);

  // Redirect if user manually navigated here and should not be here
  useEffect(() => {
    if (
      !userOptingIn &&
      loggedInUser &&
      !loggedInUser.loggedInSongtrustUser?.mfaOptIn
    ) {
      navigate('/dashboard');
    }
  }, [loggedInUser]);

  // Get the phone number from location state or session storage
  const storedMaskedPhoneNumber = getMaskedPhoneNumber();
  const maskedPhoneNumber =
    location?.state?.maskedPhoneNumber || storedMaskedPhoneNumber;

  const mfaForm = useForm<MfaFormValues>({
    defaultValues: {
      'mfa-phone-number': userOptingIn ? '' : maskedPhoneNumber,
      'mfa-code': '',
      'mfa-channel': ChannelEnum.Sms,
      'device-id': null,
    },
  });

  const handleCancel = () => {
    if (userOptingIn) {
      navigate('/account/security');
    } else {
      navigate('/logout');
    }
  };

  return (
    <Container>
      <Grid container data-testid="mfa-container">
        <FormProvider {...mfaForm}>
          {mfaStep === 1 && (
            <MfaFormStepOne
              t={t}
              setMfaStep={setMfaStep}
              handleCancel={handleCancel}
            />
          )}
          {mfaStep === 2 && (
            <MfaFormStepTwo t={t} handleCancel={handleCancel} />
          )}
        </FormProvider>
      </Grid>
    </Container>
  );
}

export default MfaPage;
