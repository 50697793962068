import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CreateIcon from '@mui/icons-material/Create';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import lodPreviewPdf from '~/assets/pdf/lod_preview_songtrust.pdf';
import FormInput from '~/base/components/FormInput';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { Translator } from '~/types/Translator';
import './AddSongtrustWriterSignature.scss';

export const SIGNATURE_FORM_NAME = `signature`;

type AddSongtrustWriterSignatureProps = Translator;
function AddSongtrustWriterSignature({ t }: AddSongtrustWriterSignatureProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sigRef = useRef() as React.MutableRefObject<any>;
  const {
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useFormContext();

  const [signature, setSignature] = useState(null);
  const [isTypeMode, setIsTypeMode] = useState<boolean>(false);
  const [hasBegun, setHasBegun] = useState<boolean>(false);
  const [signatureAccepted, setSignatureAccepted] = useState<boolean>(false);

  const convertNameToSignature = (name: string) => {
    const canvas = document.createElement('canvas');
    canvas.width = 400;
    canvas.height = 200;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.font = 'italic 30px cursive';
      ctx.fillStyle = '#000';
      ctx.fillText(name, 10, 50);
    }

    const base64Image = canvas.toDataURL('image/png');
    return base64Image;
  };

  const handleSignatureAccept = () => {
    if (isTypeMode) {
      if (getValues('signature-name').toString() !== '') {
        const base64Image = convertNameToSignature(getValues('signature-name'));
        setValue(SIGNATURE_FORM_NAME, base64Image);
        setSignatureAccepted(true);
      } else {
        setError('signature-name', {
          message: t(
            'page.add-songwriter.form.songwriter.signature.type-required',
          ),
        });
        setSignatureAccepted(false);
      }
    } else {
      setValue(SIGNATURE_FORM_NAME, signature);
      setSignatureAccepted(true);
    }
  };

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };

  const handleClearSignature = () => {
    if (sigRef.current) {
      sigRef.current.clear();
    }

    setHasBegun(false);
    setSignatureAccepted(false);
    setSignature(null);
    setValue('signature-name', undefined);
    setValue(SIGNATURE_FORM_NAME, undefined);
  };

  const handleTypeModeSwitch = () => {
    handleClearSignature();
    setIsTypeMode(!isTypeMode);
  };

  useEffect(() => {
    if (sigRef.current) {
      if (signatureAccepted) {
        sigRef.current.off();
      } else {
        sigRef.current.on();
      }
    }
  }, [signatureAccepted]);

  return (
    <Grid
      container
      spacing={2}
      data-testid="add-songtrust-writer-signature"
      sx={{ pb: '2rem', pt: '2rem' }}
    >
      <Grid item xs={12}>
        <SectionTitle>
          {t('page.add-songwriter.form.songwriter.signature.title')}
        </SectionTitle>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              {t('page.add-songwriter.form.songwriter.signature.allow-text')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              {`${t('page.add-songwriter.form.songwriter.signature.review')} `}
              <Link href={lodPreviewPdf} target="_blank">
                {t('page.add-songwriter.form.songwriter.signature.here')}
              </Link>
              .
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          data-testid="signature-canvas"
          sx={{
            display: { xs: 'block', sm: 'inline-block' },
            background: '#f5f5f5',
            p: '2rem',
            margin: '0 auto',
            width: '100%',
            border: errors.signature ? '2px solid #F33126' : 'none', // Conditionally apply border
          }}
        >
          {!isTypeMode && (
            <SignatureCanvas
              penColor="#000"
              backgroundColor="#fff"
              canvasProps={{
                height: 125,
                className: 'signaturePad',
              }}
              ref={sigRef}
              onEnd={handleSignatureEnd}
              onBegin={() => {
                setHasBegun(true);
              }}
            />
          )}

          {isTypeMode && (
            <FormInput
              sx={{
                width: '100%',
                '.MuiInputBase-root': {
                  pt: '3rem !important',
                  pb: '3rem !important',
                },
                '.MuiInputBase-input': {
                  fontFamily: ['DawningOfANewDay', 'cursive'].join(','),
                  fontSize: '46px !important',
                },
              }}
              id="signature-name"
              data-testid="signature-name"
              name="signature-name"
              label={t(
                'page.add-songwriter.form.songwriter.signature.type-label',
              )}
              required={{
                value: true,
                message: t(
                  'page.add-songwriter.form.songwriter.signature.type-required',
                ),
              }}
              disabled={signatureAccepted}
              clearErrorOnFocus
            />
          )}

          {!signatureAccepted && (
            <Button
              data-testid="switch-button"
              sx={{
                float: 'right',
                pl: 2,
                pr: 2,
                mt: 2,
              }}
              onClick={handleTypeModeSwitch}
            >
              {!isTypeMode && (
                <>
                  {t(
                    'page.add-songwriter.form.songwriter.signature.prefer-type',
                  )}
                  <KeyboardIcon sx={{ ml: 1 }} />
                </>
              )}

              {isTypeMode && (
                <>
                  {t(
                    'page.add-songwriter.form.songwriter.signature.prefer-sign',
                  )}
                  <CreateIcon sx={{ ml: 1 }} />
                </>
              )}
            </Button>
          )}
        </Box>
        {errors.signature && (
          <Typography sx={{ color: '#F33126' }}>
            {t('page.add-songwriter.form.songwriter.signature.type-required')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6} />

      <Grid item xs={6} md={3}>
        {signatureAccepted && (
          <Button
            data-testid="clear-button"
            sx={{ float: 'left', pr: 2, pl: 2 }}
            onClick={handleClearSignature}
          >
            <CancelIcon sx={{ mr: 1 }} />
            {t('page.add-songwriter.form.songwriter.signature.clear')}
          </Button>
        )}
      </Grid>
      <Grid item xs={6} md={3}>
        {!signatureAccepted && ((hasBegun && !isTypeMode) || isTypeMode) && (
          <Button
            data-testid="accept-button"
            sx={{ float: 'right', pr: 2, pl: 2 }}
            onClick={handleSignatureAccept}
          >
            {t('page.add-songwriter.form.songwriter.signature.accept')}
            <CheckCircleOutlineIcon sx={{ ml: 1 }} />
          </Button>
        )}
        {signatureAccepted && (
          <Button
            sx={{
              float: 'right',
              pr: 2,
              pl: 2,
              color: 'green',
              ':hover': { cursor: 'default', backgroundColor: '#fff' },
            }}
          >
            {t('page.add-songwriter.form.songwriter.signature.accepted')}
            <CheckCircleIcon sx={{ ml: 1 }} />
          </Button>
        )}
      </Grid>
      <Grid item xs={6} />
    </Grid>
  );
}

export default AddSongtrustWriterSignature;
