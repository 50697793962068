import { ApolloError } from '@apollo/client';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {
  Button,
  FormControl,
  FormHelperText,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import validator from 'validator';
import FormInput from '~/base/components/FormInput';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import SwitchNameEnum from '~/base/enums/switchNameEnum';
import useFeatureFlag from '~/base/hooks/useFeatureFlag';
import { setMfaVerificationRequired } from '~/base/utils/mfaUtils';
import { currentSessionTokenVar } from '~/cache';
import { SessionToken } from '~/types/SessionToken.model';
import { Translator } from '~/types/Translator';
import {
  ChannelEnum,
  LoginUserMutation,
  useLoginUserMutation,
  useRequestMfaCodeMutation,
} from '~/types/generated/graphql';

function LoginPageForm({ t }: Translator) {
  const [processError, setProcessError] = useState<string>('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { hasFlag: mfaSwitch } = useFeatureFlag(
    SwitchNameEnum.MFA,
    FlagTypeEnum.SWITCH,
  );

  const [loginUser, { loading: loginLoading, error: loginError }] =
    useLoginUserMutation({
      fetchPolicy: 'network-only',
    });

  const formMethods = useForm();
  const [
    sendMfaCode,
    { loading: loginSendMfaCodeLoading, error: sendMfaCodeError },
  ] = useRequestMfaCodeMutation({
    onCompleted: (data) => {
      if (data?.sendMfaCode?.maskedPhoneNumber) {
        navigate('/login/authenticate', {
          state: { maskedPhoneNumber: data.sendMfaCode.maskedPhoneNumber },
        });
      } else {
        const errorMessages =
          data?.sendMfaCode?.errors?.map((error) => error?.error).join(' | ') ||
          '';
        setProcessError(errorMessages);
      }
    },
    onError: (error: ApolloError) => {
      setProcessError(error.message);
    },
  });

  const handleLoginComplete = (data: LoginUserMutation) => {
    const sessionToken: SessionToken = {
      token: data?.tokenAuth?.token as string,
      refreshToken: data?.tokenAuth?.refreshToken as string,
      refreshExpiresIn: data?.tokenAuth?.refreshExpiresIn as number,
    };

    currentSessionTokenVar(sessionToken);

    const next = searchParams.get('next');
    const { hash } = window.location;

    if (mfaSwitch && data?.tokenAuth?.verificationRequired) {
      sendMfaCode({
        variables: {
          channel: ChannelEnum.Sms,
        },
        onCompleted: (mfaData) => {
          if (mfaData?.sendMfaCode?.maskedPhoneNumber) {
            const navigationState: Record<string, string> = {
              maskedPhoneNumber: mfaData.sendMfaCode.maskedPhoneNumber,
            };

            if (next) {
              navigationState.next = next;
              navigationState.hash = hash;
            }

            // Store the masked phone number along with the MFA flag
            setMfaVerificationRequired(mfaData.sendMfaCode.maskedPhoneNumber);
            navigate('/login/authenticate', { state: navigationState });
          } else {
            const errorMessages =
              mfaData?.sendMfaCode?.errors
                ?.map((error) => error?.error)
                .join(' | ') || '';
            setProcessError(errorMessages);
          }
        },
      });
      return;
    }

    if (next) {
      const navigateTo = `${import.meta.env.VITE_LEGACY_APP_AUTH_REDIRECT}${next}${hash}`;
      window.location.replace(navigateTo);
    } else {
      navigate('/dashboard');
    }
  };

  const handleLoginError = (inError: ApolloError | undefined) => {
    if (inError) setProcessError(inError.message);
  };

  const loginClickHandler = async (values: FieldValues) => {
    const fp = await FingerprintJS.load();
    const fpResult = await fp.get();
    if (processError) setProcessError('');

    loginUser({
      variables: {
        fp: fpResult.visitorId,
        username: values.email,
        password: values.password,
      },
      onCompleted: handleLoginComplete,
      onError: handleLoginError,
    });
  };

  const loginTextFieldStyle = {
    marginBottom: '2rem',
    '& .MuiOutlinedInput-root': {
      marginTop: '1rem',
      '& > fieldset': {
        border: '2px solid #7E7E7E',
        borderRadius: '.5rem',
      },
      '&.Mui-focused, &:hover': {
        '& > fieldset': {
          borderColor: '#031C9B',
        },
      },
    },
  };

  return (
    <div className="login-page-form" data-testid="login-page-form">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(loginClickHandler)}>
          <Stack direction="column">
            <Typography
              variant="h2"
              component="h2"
              sx={{ textAlign: 'center', marginBottom: '2rem' }}
            >
              {t('form.title')}
            </Typography>

            <FormInput
              sx={{
                width: '70%',
                marginLeft: 'auto',
                marginRight: 'auto',
                ...loginTextFieldStyle,
              }}
              id="login-username"
              name="email"
              label={t('form.email')}
              required={{
                value: true,
                message: t('form.validation.email.required'),
              }}
              validate={{
                isEmailValid: (v) =>
                  validator.isEmail(v as string) ||
                  t('form.validation.email.invalid'),
              }}
            />

            <FormInput
              sx={{
                width: '70%',
                marginLeft: 'auto',
                marginRight: 'auto',
                ...loginTextFieldStyle,
              }}
              label={t('form.password')}
              name="password"
              id="login-password"
              required={{
                value: true,
                message: t('form.validation.password.required'),
              }}
              type="password"
            >
              <Link
                component="a"
                href="/forgot-password"
                data-testid="forgot-password-link"
                sx={{
                  textAlign: 'right',
                  top: '-0.75rem',
                  right: '0rem',
                  zIndex: '999',
                  position: 'absolute',
                }}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  navigate('/forgot-password');
                }}
              >
                {t('form.forgot_password')}
              </Link>
            </FormInput>

            <FormControl
              sx={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <Button
                data-testid="login-button"
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                }}
                disabled={loginLoading || loginSendMfaCodeLoading}
              >
                {t('form.login')}
              </Button>
              <FormHelperText
                sx={{
                  color: loginError || sendMfaCodeError ? '#F33126' : 'inherit',
                  fontSize: '.75rem',
                  textAlign: 'center',
                }}
              >
                {(loginLoading || loginSendMfaCodeLoading) && (
                  <span>Logging in...</span>
                )}
                {processError && (
                  <span data-testid="login-error-message">
                    Oh no! {processError}
                  </span>
                )}
              </FormHelperText>
            </FormControl>
          </Stack>
        </form>
      </FormProvider>
    </div>
  );
}

export default LoginPageForm;
