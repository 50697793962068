import { Button, Container, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import accountConstants from '~/account/constants/accountConstants';
import FullScreenMessage from '~/base/components/FullScreenMessage/FullScreenMessage';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import SectionTipCard from '~/base/components/SectionTipCard';
import usePageTitle from '~/hooks/usePageTitle';
import { useTrolleyStringQuery } from '~/types/generated/graphql';
import TrolleyTaxIframe from './TrollyTaxIframe';

function TaxDocumentationPage() {
  const navigate = useNavigate();
  const { t } = useTranslation(['account']);
  const { data } = useTrolleyStringQuery({
    fetchPolicy: 'network-only',
  });

  const [searchParams] = useSearchParams();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(
    searchParams.get('q') === 'showDialog',
  );
  const handleModalClose = () => {
    setIsModalOpen(!isModalOpen);
    navigate(accountConstants.taxSettingsRoute);
  };

  usePageTitle(t('sections.tax-information.title'));

  return (
    <div className="tax-page" data-testid="tax-documentation-page">
      <FullScreenMessage
        isOpen={isModalOpen}
        allowBackdropClose={false}
        allowEscClose={false}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" component="h1">
              {t('sections.tax-information.modal.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              {t('sections.tax-information.modal.content')}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleModalClose();
              }}
            >
              {t('sections.tax-information.modal.button')}
            </Button>
          </Grid>
        </Grid>
      </FullScreenMessage>

      {(data?.trolleyString && (
        <Container>
          <PageTitle
            currentPage={t('sections.tax-information.title')}
            breadcrumbLinks={[
              {
                id: 'account-tax-information-back-link',
                label: t('sections.account-information.breadcrumb'),
                href: '/account',
              },
            ]}
            pageTitle={{
              title: t('sections.tax-information.title'),
              id: 'tax-documentation-page-title',
            }}
          />

          <Grid
            container
            sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
            }}
          >
            <TrolleyTaxIframe
              t={t}
              id="trolley-frame"
              title="Trolley IFrame Component"
              src={data?.trolleyString}
            />
            <Grid item xs={12} md={3}>
              <Grid container item rowSpacing={4}>
                <Grid item>
                  <SectionTipCard
                    title={t('sections.tax-information.trolley.title')}
                  >
                    <div>
                      <span>
                        {t('sections.tax-information.trolley.content.header')}
                      </span>
                      <ul>
                        <li>
                          <div>
                            {t(
                              'sections.tax-information.trolley.content.bank-transfer.title',
                            )}
                          </div>
                          <div>
                            {t(
                              'sections.tax-information.trolley.content.bank-transfer.explanation',
                            )}
                          </div>
                        </li>
                        <li>
                          {t('sections.tax-information.trolley.content.paypal')}
                        </li>
                      </ul>
                      <div style={{ fontStyle: 'italic' }}>
                        {t('sections.tax-information.trolley.content.notes')}
                      </div>
                      <div>
                        {t('sections.tax-information.trolley.help')}
                        <Link href={accountConstants.helpCenterPaymentsLink}>
                          {t('sections.tax-information.trolley.help-link-text')}
                        </Link>
                        .
                      </div>
                    </div>
                  </SectionTipCard>
                </Grid>
                <Grid item>
                  <SectionTipCard
                    title={t('sections.tax-information.payments.title')}
                    color="blue"
                  >
                    <div>
                      <div>
                        {t('sections.tax-information.payments.explanation')}
                      </div>
                      <br />
                      <div>{t('sections.tax-information.payments.submit')}</div>
                      <br />
                      <div>
                        {t('sections.tax-information.payments.help')}
                        <Link
                          href={accountConstants.helpCenterTaxInformationLink}
                        >
                          {t(
                            'sections.tax-information.payments.help-link-text',
                          )}
                        </Link>
                        .
                      </div>
                    </div>
                  </SectionTipCard>
                </Grid>

                <Grid item>
                  <SectionTipCard
                    title={t('sections.tax-information.schedule.title')}
                    color="green"
                  >
                    <div>
                      <div>
                        {t('sections.tax-information.schedule.explanation')}
                      </div>
                      <br />
                      <div>
                        {t('sections.tax-information.schedule.history')}
                        <Link
                          component={RouterLink}
                          to={accountConstants.accountHistoryRoute}
                        >
                          {t(
                            'sections.tax-information.schedule.history-link-text',
                          )}
                        </Link>
                        .
                      </div>
                    </div>
                  </SectionTipCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )) || <div />}
    </div>
  );
}

export default TaxDocumentationPage;
