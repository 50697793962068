/**
 * MFA verification status key in session storage
 */
export const MFA_VERIFICATION_REQUIRED = 'mfa_verification_required';
export const MFA_MASKED_PHONE_NUMBER = 'mfa_masked_phone_number';

/**
 * Sets a flag in session storage indicating MFA verification is required
 * @param maskedPhoneNumber Optional masked phone number to store
 */
export const setMfaVerificationRequired = (
  maskedPhoneNumber?: string,
): void => {
  window.sessionStorage.setItem(MFA_VERIFICATION_REQUIRED, 'true');
  if (maskedPhoneNumber) {
    window.sessionStorage.setItem(MFA_MASKED_PHONE_NUMBER, maskedPhoneNumber);
  }
};

/**
 * Clears all MFA verification data from session storage
 */
export const clearMfaVerificationRequired = (): void => {
  window.sessionStorage.removeItem(MFA_VERIFICATION_REQUIRED);
  window.sessionStorage.removeItem(MFA_MASKED_PHONE_NUMBER);
};

/**
 * Checks if MFA verification is required based on session storage flag
 * @returns {boolean} True if MFA verification is required
 */
export const isMfaVerificationRequired = (): boolean => {
  return window.sessionStorage.getItem(MFA_VERIFICATION_REQUIRED) === 'true';
};

/**
 * Gets the stored masked phone number if available
 * @returns {string|null} The masked phone number or null
 */
export const getMaskedPhoneNumber = (): string | null => {
  return window.sessionStorage.getItem(MFA_MASKED_PHONE_NUMBER);
};
