const accountConstants = {
  ACCOUNT_KYC_PATHS: ['/account/verify-identity'],
  ACCOUNT_PATHS: [
    '/account/tax-documentation',
    '/account/account-information',
    '/account/change-password',
    '/account/reset-requested',
    '/account/billing-information',
    '/account/account-history',
    '/account/access-management',
    '/account/security',
    '/account/security/opt-in-mfa',
    '/login/authenticate', // May be unnecessary but assures users requiring tax info will not be redirected from mfa verification if they're opted in to mfa
  ],
  helpCenterPaymentsLink: 'https://help.songtrust.com/knowledge/payments',
  helpCenterTaxInformationLink:
    'https://help.songtrust.com/knowledge/payments#tax-information',
  accountHistoryRoute: '/account/account-history',
  taxSettingsRoute: '/account/tax-documentation',
  kycVerificationRoute: '/account/verify-identity',
  kycVideoId: '2Sk5HKF84PM',
  currencySymbols: {
    AUD: '$',
    BRL: 'R$',
    CNY: '¥',
    CRC: '₡',
    EUR: '€',
    GBP: '£',
    HKD: '$',
    ILS: '₪',
    INR: '₹',
    JPY: '¥',
    KRW: '₩',
    LKR: '₨',
    NGN: '₦',
    NZD: '$',
    PHP: '₱',
    PLN: 'zł',
    PYG: '₲',
    SEK: 'kr',
    THB: '฿',
    TWD: 'NT$',
    TTD: 'TT$',
    UAH: '₴',
    RUB: '₽',
    VND: '₫',
    USD: '$',
    YER: '﷼',
    ZWD: 'Z$',
  },
};

export const { ACCOUNT_PATHS, ACCOUNT_KYC_PATHS } = accountConstants;
export default accountConstants;
