import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import usePageTitle from '~/hooks/usePageTitle';
import { useTrolleyStringQuery } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import TrolleyTaxIframe from './TrollyTaxIframe';

function TaxInformation2024Page({ t }: Translator) {
  const navigate = useNavigate();
  const { data } = useTrolleyStringQuery({
    fetchPolicy: 'network-only',
  });

  usePageTitle(t('sections.tax-information.title'));

  return (
    <div className="tax-2024-page" data-testid="2024-tax-information-page">
      {(data?.trolleyTaxString && (
        <Container>
          <PageTitle
            currentPage={t('sections.tax-information.trolley.tax-title')}
            breadcrumbLinks={[
              {
                id: 'account-tax-information-back-link',
                label: t('sections.account-information.breadcrumb'),
                href: '/account',
              },
              {
                id: 'tax-documentation-back-link',
                label: t('sections.tax-information.title'),
                href: '/account/tax-documentation',
              },
            ]}
            pageTitle={{
              title: t('sections.tax-information.trolley.tax-title'),
              id: '2024-tax-information-page-title',
            }}
          />
          <Grid container>
            <Grid item xs={12} md={8}>
              <Typography
                variant="body1"
                component="p"
                sx={{ ml: 2, fontWeight: 'bold' }}
              >
                {t('sections.tax-information.pre-content')}
              </Typography>
              <br />
              <Typography variant="body1" component="p" sx={{ ml: 2 }}>
                {t('sections.tax-information.content-second')}
              </Typography>
              <br />
              <Typography variant="body1" component="p" sx={{ ml: 2 }}>
                {t('sections.tax-information.content-continued-second')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12}>
              <TrolleyTaxIframe
                t={t}
                id="trolley-tax-frame"
                title="Trolley Tax IFrame Component"
                src={data?.trolleyTaxString}
              />
            </Grid>
          </Grid>
          <Button
            id="2024-tax-button"
            variant="outlined"
            sx={{ alignSelf: 'flex-end' }}
            onClick={() => navigate('/account/tax-documentation')}
          >
            <ArrowBackIcon sx={{ fontSize: '1.75rem', pr: '0.5rem' }} />
            {t('sections.tax-information.buttons.back')}
          </Button>
        </Container>
      )) || <div />}
    </div>
  );
}

export default TaxInformation2024Page;
