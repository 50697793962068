import { createElement } from 'react';
import AddOutsideSongwriter from '../components/Header/HeaderMenuLinksSubmenu/AddOutsideSongwriter';
import AddSongtrustSongwriter from '../components/Header/HeaderMenuLinksSubmenu/AddSongtrustSongwriter';

const accountAccessLinks = [
  {
    textOrComponent: 'header.internal.account-information',
    url: '/account/account-information',
    type: 'link',
    index: 0,
  },
  {
    textOrComponent: 'header.internal.change-password',
    url: '/account/change-password',
    type: 'link',
    index: 1,
  },
  {
    textOrComponent: 'header.internal.payment-tax',
    url: '/account/tax-documentation',
    type: 'link',
    index: 3,
  },
  {
    textOrComponent: 'header.internal.account-history',
    url: '/account/account-history',
    type: 'link',
    index: 4,
  },
  {
    textOrComponent: 'header.internal.access-management',
    url: '/account/access-management',
    type: 'link',
    index: 5,
  },
];

const headerConstants = {
  LANDING_PAGE_HEADER_PATHS: [
    '/account/verify-identity',
    '/account/reset-requested',
    '/logout',
    '/get-started',
    '/onboarding',
    '/signup',
    '/account/security/opt-in-mfa',
    '/login/authenticate',
  ],
  LOGGED_IN_HEADER_PROFILE_LINKS: accountAccessLinks,
  LOGGED_IN_HEADER_LINKS: [
    {
      hideWhenInactive: false,
      textOrComponent: 'header.internal.dashboard',
      url: '/dashboard',
      type: 'link',
      index: 0,
    },
    {
      hideWhenInactive: true,
      textOrComponent: 'header.internal.songwriters',
      menuItems: [
        {
          textOrComponent: 'header.internal.manage-songwriters',
          url: '/songwriters',
          type: 'link',
          index: 0,
        },
        {
          textOrComponent: createElement(AddSongtrustSongwriter),
          url: '/songwriters/add',
          type: 'link',
          index: 2,
        },
        {
          textOrComponent: createElement(AddOutsideSongwriter),
          url: '/songwriters/add/outside',
          type: 'link',
          index: 1,
        },
      ],
      type: 'menu',
      index: 4,
    },
    {
      hideWhenInactive: true,
      textOrComponent: 'header.internal.songs',
      menuItems: [
        {
          textOrComponent: 'header.internal.manage-songs',
          url: '/songs',
          type: 'link',
          index: 0,
        },
        {
          textOrComponent: 'header.internal.add-song',
          url: '/songs/add',
          type: 'link',
          index: 1,
        },
      ],
      type: 'menu',
      index: 5,
    },
    {
      hideWhenInactive: false,
      textOrComponent: 'header.internal.royalties',
      url: '/royalties',
      type: 'link',
      index: 1,
    },
    {
      hideWhenInactive: true,
      textOrComponent: 'header.internal.youtube',
      url: '/youtube',
      type: 'link',
      index: 3,
    },
    {
      hideWhenInactive: false,
      hideWhenNotMobile: true,
      textOrComponent: 'header.internal.account-settings',
      menuItems: accountAccessLinks,
      type: 'menu',
      index: 7,
    },
  ],
  LOGGED_OUT_HEADER_LINKS: [
    {
      textOrComponent: 'header.external.explore-songtrust',
      url: 'https://www.songtrust.com/why-songtrust',
      type: 'link',
      index: 1,
    },
    {
      textOrComponent: 'header.external.services',
      url: 'https://www.songtrust.com/platform-features',
      type: 'link',
      index: 2,
    },
    {
      textOrComponent: 'header.external.pricing',
      url: 'https://www.songtrust.com/pricing',
      type: 'link',
      index: 3,
    },
    {
      textOrComponent: 'header.external.learn',
      menuItems: [
        {
          textOrComponent: 'header.external.blog',
          url: 'https://blog.songtrust.com/',
          type: 'link',
          index: 0,
        },
        {
          textOrComponent: 'header.external.resources',
          url: 'https://www.songtrust.com/resource-center',
          type: 'link',
          index: 1,
        },
        {
          textOrComponent: 'header.external.songtrust-en-espanol',
          url: 'https://www.songtrust.com/es/centro-de-recursos',
          type: 'link',
          index: 2,
        },
        {
          textOrComponent: 'header.external.music-glossary',
          url: 'https://www.songtrust.com/music-publishing-glossary',
          type: 'link',
          index: 3,
        },
        {
          textOrComponent: 'header.external.collection-society-database',
          url: 'https://www.songtrust.com/paysource-database',
          type: 'link',
          index: 4,
        },
        {
          textOrComponent: 'header.external.help-center',
          url: 'https://help.songtrust.com/knowledge',
          type: 'link',
          index: 5,
        },
      ],
      type: 'menu',
      index: 4,
    },
  ],
};

export const {
  LANDING_PAGE_HEADER_PATHS,
  LOGGED_IN_HEADER_PROFILE_LINKS,
  LOGGED_IN_HEADER_LINKS,
  LOGGED_OUT_HEADER_LINKS,
} = headerConstants;

export default headerConstants;
