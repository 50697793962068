import { ApolloError, useReactiveVar } from '@apollo/client';
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import { MfaFormValues } from '~/account/types/types';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import { clearMfaVerificationRequired } from '~/base/utils/mfaUtils';
import { currentSongtrustUserPersonaVar } from '~/cache';
import {
  ChannelEnum,
  LoggedInSongtrustUserPersonaQuery,
  useConfirmMfaCodeMutation,
  useConfirmPhoneDeviceMutation,
  useRequestMfaCodeMutation,
  useSetupPhoneDeviceMutation,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import './verification-input.css';

interface MfaFormStepTwoProps extends Translator {
  handleCancel: () => void;
}

function MfaFormStepTwo({ t, handleCancel }: MfaFormStepTwoProps) {
  // State & Hooks
  const [processError, setProcessError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [retryDisabled, setRetryDisabled] = useState<boolean>(true);
  const [retryTimer, setRetryTimer] = useState<number>(30);
  const [attempts, setAttempts] = useState<number>(1);
  const redirectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const userOptingIn = location.pathname.includes('opt-in-mfa');
  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);

  // Helpers
  const { handleSubmit, setValue, getValues } = useFormContext<MfaFormValues>();
  const clearBanner = () => {
    if (processError) setProcessError('');
    if (successMessage) setSuccessMessage('');
  };

  // Retry logic
  const retryTimeOut = (attemptNumber: number): number => {
    const timeouts = {
      2: 60,
      3: 90,
      4: 120,
    };
    return timeouts[attemptNumber as 2 | 3 | 4] || 600;
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (retryTimer > 0) {
      timer = setTimeout(() => setRetryTimer((prev) => prev - 1), 1000);
    } else if (retryTimer === 0) {
      setRetryDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [retryTimer]);

  const handleRetry = () => {
    setRetryDisabled(true);
    const nextAttempts = attempts + 1;
    setAttempts(nextAttempts);
    setRetryTimer(retryTimeOut(nextAttempts));
    clearBanner();
  };

  // Clean up the redirect timeout on unmount
  useEffect(() => {
    return () => {
      if (redirectTimeoutRef.current) {
        clearTimeout(redirectTimeoutRef.current);
      }
    };
  }, []);

  // Mutations
  // Opting in verify code mutation
  const [verifyPhoneDevice, { loading: optInVerifyLoading }] =
    useConfirmPhoneDeviceMutation({
      onCompleted: (data) => {
        if (data?.verifyPhoneDevice?.success) {
          // Update reactive variable to update across site.
          const updatedUser = {
            ...loggedInUser,
            loggedInSongtrustUser: {
              ...loggedInUser?.loggedInSongtrustUser,
              mfaOptIn: true,
            },
          };
          currentSongtrustUserPersonaVar(
            updatedUser as LoggedInSongtrustUserPersonaQuery,
          );
          setSuccessMessage(t('sections.mfaVerificationStep2.success'));
          // Store the timeout ID so we can clear it if user manually navigates away prior to redirect
          redirectTimeoutRef.current = setTimeout(
            () => navigate('/account/security'),
            5000,
          );
        } else {
          const errorMessages =
            data?.verifyPhoneDevice?.errors
              ?.map((error) => error?.error)
              .join(' | ') || '';
          setProcessError(errorMessages);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      onError: (error: ApolloError) => {
        setProcessError(error.message);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    });

  // Opting in send code mutation
  const [initializePhoneDevice, { loading: optInSendLoading }] =
    useSetupPhoneDeviceMutation({
      onCompleted: (data) => {
        if (data?.initializePhoneDevice?.success) {
          setValue('device-id', data?.initializePhoneDevice?.deviceId);
          setSuccessMessage(t('sections.mfaVerificationStep2.resent'));
          setTimeout(() => setSuccessMessage(''), 5000);
        } else {
          const errorMessages =
            data?.initializePhoneDevice?.errors
              ?.map((error) => error?.error)
              .join(' | ') || '';
          setProcessError(errorMessages);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      onError: (error: ApolloError) => {
        setProcessError(error.message);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    });

  // Login verify code mutation
  const [verifyMfaCode, { loading: loginVerifyLoading }] =
    useConfirmMfaCodeMutation({
      onCompleted: (data) => {
        if (data?.verifyMfaCode?.success) {
          // Clear MFA verification flag on successful verification
          clearMfaVerificationRequired();

          const next = location.state?.next;
          if (next) {
            const hash = location.state?.hash;
            const navigateTo = `${import.meta.env.VITE_LEGACY_APP_AUTH_REDIRECT}${next}${hash}`;
            window.location.replace(navigateTo);
          } else {
            navigate('/dashboard');
          }
        } else {
          const errorMessages =
            data?.verifyMfaCode?.errors
              ?.map((error) => error?.error)
              .join(' | ') || '';
          setProcessError(errorMessages);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      onError: (error: ApolloError) => {
        setProcessError(error.message);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    });

  // Login send code mutation used for resend functionality
  const [sendMfaCode, { loading: loginSendLoading }] =
    useRequestMfaCodeMutation({
      onCompleted: (data) => {
        if (data?.sendMfaCode?.maskedPhoneNumber) {
          setSuccessMessage(t('sections.mfaVerificationStep2.resent'));
          setTimeout(() => setSuccessMessage(''), 5000);
        } else {
          const errorMessages =
            data?.sendMfaCode?.errors
              ?.map((error) => error?.error)
              .join(' | ') || '';
          setProcessError(errorMessages);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      onError: (error: ApolloError) => {
        setProcessError(error.message);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    });

  // Consolidate loading states
  const isLoading =
    optInVerifyLoading ||
    optInSendLoading ||
    loginVerifyLoading ||
    loginSendLoading;
  const isCodeComplete = code.length === 6;
  const isActionDisabled = isLoading || successMessage !== '';
  const isSubmitDisabled = isActionDisabled || !isCodeComplete;

  // Form handlers
  const onSubmit = (values: MfaFormValues) => {
    clearBanner();

    if (userOptingIn && values['device-id']) {
      verifyPhoneDevice({
        variables: {
          input: {
            deviceId: values['device-id'],
            code: values['mfa-code'],
          },
        },
      });
    } else {
      verifyMfaCode({
        variables: {
          input: {
            code: values['mfa-code'],
          },
        },
      });
    }
  };

  // Verification handlers
  const handleCodeChange = (value: string) => {
    setCode(value);
  };
  const handleCodeComplete = (value: string) => {
    setValue('mfa-code', value);
  };

  // Resend handlers
  const handleResend = () => {
    handleRetry();
    if (userOptingIn) {
      initializePhoneDevice({
        variables: {
          input: {
            optInPhoneNumber: getValues('mfa-phone-number') || '',
            channel: getValues('mfa-channel'),
          },
        },
      });
    } else {
      sendMfaCode({
        variables: {
          channel: ChannelEnum.Sms,
        },
      });
    }
  };

  const handleSwapChannelResend = () => {
    handleRetry();
    if (userOptingIn) {
      initializePhoneDevice({
        variables: {
          input: {
            optInPhoneNumber: getValues('mfa-phone-number') || '',
            channel:
              getValues('mfa-channel') === ChannelEnum.Sms
                ? ChannelEnum.Call
                : ChannelEnum.Sms,
          },
        },
      });
    } else {
      sendMfaCode({
        variables: {
          channel:
            getValues('mfa-channel') === ChannelEnum.Sms
              ? ChannelEnum.Call
              : ChannelEnum.Sms,
        },
      });
    }
  };

  // Display
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <form id="mfaFormStepTwo" onSubmit={handleSubmit(onSubmit)}>
      <Grid container item xs={12}>
        <Grid item>
          {processError && (
            <FormBanner text={processError} type={FormBannerType.ERROR} />
          )}
          {successMessage && (
            <FormBanner text={successMessage} type={FormBannerType.SUCCESS} />
          )}
          {/* Header text */}
          <Typography variant="h1" data-testid="mfa-form-step-two-title">
            {t('sections.mfaVerificationStep2.title')}
          </Typography>

          {/* Copy explaining which number code was sent to */}
          {userOptingIn ? (
            <Typography>
              {t('sections.mfaVerificationStep2.opt-in-phone-number')}{' '}
              {getValues('mfa-phone-number')}.
            </Typography>
          ) : (
            <Typography>
              {t('sections.mfaVerificationStep2.sanitized-phone-number')}{' '}
              {location.state?.maskedPhoneNumber ||
                getValues('mfa-phone-number')}
            </Typography>
          )}

          {/* MFA code input */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
              {t('sections.mfaVerificationStep2.label')}
            </Typography>
            <VerificationInput
              value={code}
              onChange={handleCodeChange}
              onComplete={handleCodeComplete}
              length={6}
              validChars="0-9"
              placeholder="-"
              autoFocus
              classNames={{
                character: 'character',
              }}
              inputProps={{ id: 'mfa-step-two-code-input' }}
            />
          </Grid>
        </Grid>

        {/* Didn't receive / Retry header */}
        <Grid container item xs={12} sx={{ pt: 5 }}>
          <Typography>
            {t('sections.mfaVerificationStep2.did-not-receive')}
            {retryDisabled &&
              ` ${t('sections.mfaVerificationStep2.retry')} ${retryTimer}`}
          </Typography>
        </Grid>

        {/* Actions */}
        <Grid
          container
          item
          xs={12}
          sm={9}
          sx={{
            mt: 0,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* Resend Code / Receive opposite channel instead */}
          <Grid item sm={6} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
            <Button
              onClick={handleResend}
              sx={{
                p: 0,
                pr: 2,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              disabled={retryDisabled || isActionDisabled}
            >
              <Typography
                variant="h6"
                component="a"
                sx={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  textTransform: 'none',
                  display: 'inline-block',
                }}
              >
                {t('sections.mfaVerificationStep2.resend')}
              </Typography>
            </Button>
            <Button
              onClick={handleSwapChannelResend}
              sx={{
                p: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              disabled={retryDisabled || isActionDisabled}
            >
              <Typography
                variant="h6"
                component="a"
                sx={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  textTransform: 'none',
                  display: 'inline-block',
                }}
              >
                {getValues('mfa-channel') === ChannelEnum.Sms &&
                  t('sections.mfaVerificationStep2.call-instead')}
                {getValues('mfa-channel') === ChannelEnum.Call &&
                  t('sections.mfaVerificationStep2.sms-instead')}
              </Typography>
            </Button>
          </Grid>

          {/* Cancel / Verify */}
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: xs ? 'space-between' : 'flex-end',
              gap: 2,
              mt: xs ? 2 : 0,
            }}
          >
            <Button
              data-testid="cancel-step1"
              variant="outlined"
              onClick={handleCancel}
              sx={{ width: xs ? '45%' : 'auto' }}
              disabled={isLoading}
            >
              {t('sections.mfaVerificationStep1.cancel')}
            </Button>
            <Button
              data-testid="verify-step1"
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ width: xs ? '45%' : 'auto' }}
              disabled={isSubmitDisabled}
            >
              {t('sections.mfaVerificationStep1.verify')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default MfaFormStepTwo;
