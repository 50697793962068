import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import accountConstants, {
  ACCOUNT_KYC_PATHS,
  ACCOUNT_PATHS,
} from '~/account/constants/accountConstants';
import {
  getMaskedPhoneNumber,
  isMfaVerificationRequired,
} from '~/base/utils/mfaUtils';
import {
  currentSessionTokenVar,
  currentSongtrustUserPersonaVar,
} from '~/cache';
import {
  DASHBOARD_PATHS,
  FROZEN_PATHS,
  PASSWORD_RESET_PATHS,
} from '~/dashboard/constants/dashboardConstants';
import useLoginRefresh from '~/login/hooks/useLoginRefresh';
import { ONBOARDING_PATHS } from '~/onboarding/constants/onboardingConstants';
import { RefreshTokenMutation } from '~/types/generated/graphql';
import FlagTypeEnum from '../enums/flagTypeEnum';
import SwitchNameEnum from '../enums/switchNameEnum';
import useFeatureFlag from './useFeatureFlag';

const TAX_INFO_REQUIRED_ALLOWED_PATHS = [...DASHBOARD_PATHS, ...ACCOUNT_PATHS];

// Paths that are allowed when MFA verification is required
const MFA_ALLOWED_PATHS = ['/login/authenticate', '/logout'];

function useRequireAuth() {
  const [requireAuthComplete, setRequireAuthComplete] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const loggedInUserPersona = useReactiveVar(currentSongtrustUserPersonaVar);

  const userRequiresKYC =
    loggedInUserPersona?.loggedInSongtrustUser?.isKycVerificationRequired;

  const userIsFrozen =
    loggedInUserPersona?.loggedInSongtrustUser?.frozenOn !== null &&
    loggedInUserPersona?.loggedInSongtrustUser?.frozenOn !== undefined;

  const passwordChangeRequested =
    loggedInUserPersona?.loggedInSongtrustUser?.requiresPasswordChange;

  const onboardingRequested =
    loggedInUserPersona?.loggedInSongtrustUser?.requiresOnboarding;

  const isGhostedAdmin =
    loggedInUserPersona?.loggedInSongtrustUser?.isGhostedAdmin;

  const isCypressFaker = /^cypress\+[a-f0-9-]{36}@songtrust\.com$/.test(
    loggedInUserPersona?.loggedInSongtrustUser?.user.email || '',
  );

  const doesAccountTypeRequireTaxAndPaymentInformation =
    loggedInUserPersona?.loggedInSongtrustUser
      ?.doesAccountTypeRequireTaxAndPaymentInformation;
  const hasPaymentAndTaxInformation =
    loggedInUserPersona?.loggedInSongtrustUser
      ?.isPaymentAndTaxInformationComplete;

  const mustUpdateTaxInfo =
    // Bypass if account type does not require tax and payment information
    doesAccountTypeRequireTaxAndPaymentInformation &&
    // Check if user has provided tax and payment information
    !hasPaymentAndTaxInformation;
  const hasSongs = loggedInUserPersona?.loggedInSongtrustUser?.hasSongs;

  const handleLoginRefreshSuccess = (data: RefreshTokenMutation) => {
    if (!data.refreshToken) {
      navigate('/login');
    }
  };
  const handleLoginRefreshError = () => {
    navigate('/login');
  };

  const [loginRefresh] = useLoginRefresh(
    handleLoginRefreshError,
    handleLoginRefreshSuccess,
  );

  const routeUserFrozen = () => {
    if (!FROZEN_PATHS.includes(pathname)) navigate('/frozen');
  };

  const routePasswordChangeRequested = () => {
    if (!PASSWORD_RESET_PATHS.includes(pathname)) {
      navigate('/account/reset-requested');
    }

    setRequireAuthComplete(true);
  };

  const routeOnboardingRequested = () => {
    if (!ONBOARDING_PATHS.includes(pathname)) {
      navigate('/get-started');
    }

    setRequireAuthComplete(true);
  };

  const routeKYCRequired = () => {
    if (!ACCOUNT_KYC_PATHS.includes(pathname)) {
      navigate({
        pathname: accountConstants.kycVerificationRoute,
      });
    }

    setRequireAuthComplete(true);
  };

  const routeTaxInfoRequired = () => {
    if (!TAX_INFO_REQUIRED_ALLOWED_PATHS.includes(pathname)) {
      navigate({
        pathname: accountConstants.taxSettingsRoute,
        search: '?q=showDialog',
      });
    }

    setRequireAuthComplete(true);
  };

  // Check if MFA verification is required
  const { hasFlag: mfaSwitchOn } = useFeatureFlag(
    SwitchNameEnum.MFA,
    FlagTypeEnum.SWITCH,
  );
  const mfaVerificationRequired = isMfaVerificationRequired();

  const routeMfaVerificationRequired = () => {
    if (!MFA_ALLOWED_PATHS.includes(pathname)) {
      // Get the masked phone number from session storage
      const maskedPhoneNumber = getMaskedPhoneNumber();

      // Include the masked phone number in the navigation state if available
      if (maskedPhoneNumber) {
        navigate('/login/authenticate', {
          state: { maskedPhoneNumber },
          replace: true,
        });
      } else {
        navigate('/login/authenticate', { replace: true });
      }
    }
    setRequireAuthComplete(true);
  };

  useEffect(() => {
    // No session token, refresh token or go to login.
    if (!sessionToken) {
      loginRefresh();
    }

    // Redirect cases

    // 0. MFA verification is required
    if (mfaSwitchOn && mfaVerificationRequired && !isGhostedAdmin) {
      routeMfaVerificationRequired();
      return;
    }

    // 1. User's account has been frozen.
    if (userIsFrozen && !isGhostedAdmin) {
      routeUserFrozen();
      return;
    }

    // 2. User is required to change their password.
    if (passwordChangeRequested && !isGhostedAdmin) {
      routePasswordChangeRequested();
      return;
    }

    // 3. User needs to do onboarding form.
    if (onboardingRequested) {
      routeOnboardingRequested();
      return;
    }

    // 4. User needs to verify KYC.
    if (userRequiresKYC && !isGhostedAdmin) {
      routeKYCRequired();
      return;
    }

    // 5. User needs to update tax info.
    if (mustUpdateTaxInfo && hasSongs && !isGhostedAdmin && !isCypressFaker) {
      routeTaxInfoRequired();
      return;
    }

    // No special cases, user is good to go.
    setRequireAuthComplete(true);
  }, [loginRefresh, mfaVerificationRequired, pathname]);

  return { requireAuthComplete };
}

export default useRequireAuth;
