import { useReactiveVar } from '@apollo/client';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HeaderMenuLinksSubmenuDesktop from '~/base/components/Header/HeaderMenuLinksSubmenu/HeaderMenuLinksSubmenuDesktop';
import { LOGGED_IN_HEADER_PROFILE_LINKS } from '~/base/constants/headerConstants';
import useLoggedInSongtrustUserPersona from '~/base/hooks/useLoggedInSongtrustUserPersona';
import { currentSongtrustUserPersonaVar } from '~/cache';

interface HeaderPersonaMenuProps {
  /** Flag to hide the menu */
  isMenuHidden?: boolean;
}

/**
 * HeaderPersonaMenu component displays a user persona menu with profile links for logged-in users.
 *
 * @param {HeaderPersonaMenuProps} props - The properties for the HeaderPersonaMenu component.
 * @returns {JSX.Element} The rendered HeaderPersonaMenu component.
 */
function HeaderPersonaMenu({
  isMenuHidden = false,
}: HeaderPersonaMenuProps): JSX.Element {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();

  // Fetch the logged-in user's persona data
  const { data } = useLoggedInSongtrustUserPersona();

  // Use the reactive variable to get the current user persona
  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);

  // Styles for the menu links
  const HEADER_LINK_AND_ITEM_SX = {
    fontFamily: 'National2Bold',
    fontWeight: 700,
    fontSize: '1rem',
    color: '#FFFFFF',
    paddingRight: { md: 'none', lg: '1rem' },
  };

  /**
   * Handles navigation when a menu item is clicked.
   *
   * @param {string} url - The URL to navigate to.
   */
  const handleItemClick = (url: string) => {
    if (url.indexOf('http') === 0) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  // Map the header profile links for the logged-in user
  const loggedInHeaderProfileLinks = LOGGED_IN_HEADER_PROFILE_LINKS.map(
    (link) => ({ ...link }),
  );

  return (
    <Box sx={!isMenuHidden ? HEADER_LINK_AND_ITEM_SX : undefined}>
      {!isMenuHidden && data && (
        <HeaderMenuLinksSubmenuDesktop
          data-testid="header-persona-menu"
          textOrComponent={`${t('header.internal.greeting')} ${
            loggedInUser?.loggedInSongtrustUser?.user.firstName
          } ${loggedInUser?.loggedInSongtrustUser?.user.lastName.substring(
            0,
            1,
          )}!`}
          reducedText={loggedInUser?.loggedInSongtrustUser?.user?.firstName.charAt(
            0,
          )}
          menuItems={loggedInHeaderProfileLinks}
          handleItemClick={handleItemClick}
          showButtons
        />
      )}
    </Box>
  );
}

export default HeaderPersonaMenu;
