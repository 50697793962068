import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicMenuType, MenuLinkType } from '~/base/types/MenuLinkType';
import './HeaderMenuLinksSubmenuChevron.scss';

function HeaderMenuLinksSubmenuMobile({
  textOrComponent,
  menuItems,
  handleItemClick,
}: BasicMenuType) {
  const { t } = useTranslation(['translation']);

  const handleChange = () => (event: SyntheticEvent<Element, Event>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Grid
      container
      data-testid="header-submenu-mobile"
      sx={{
        display: { xs: 'flex', md: 'none' },
        width: '100vw',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Accordion
        disableGutters
        onChange={handleChange()}
        sx={{ background: '#000', width: '100%' }}
      >
        <AccordionSummary
          className="expand-menu"
          expandIcon={null}
          sx={{ marginBottom: '-.5rem', marginTop: '-.5rem' }}
        >
          <Grid item data-testid="header-menu-item" xs={12}>
            {typeof textOrComponent === 'string' ? (
              <Typography
                sx={{
                  color: '#FFFFFF',
                  textTransform: 'none',
                  padding: 0,
                  fontFamily: 'inherit',
                  fontSize: '1rem',
                  '&:hover': {
                    textDecoration: 'underline',
                    textDecorationThickness: '.125rem',
                    textUnderlineOffset: '.45rem',
                  },
                }}
              >
                {t(textOrComponent)}
                <span className="chevron" />
              </Typography>
            ) : (
              textOrComponent
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: '#FFF' }}>
          <Grid container spacing={3} sx={{ paddingTop: '.5rem' }}>
            {menuItems &&
              menuItems.map(
                ({
                  textOrComponent: itemTextOrComponent,
                  url,
                  index,
                }: MenuLinkType) => {
                  return (
                    url && (
                      <Grid
                        item
                        data-testid="header-submenu-item"
                        key={index}
                        xs={12}
                      >
                        <Link
                          href={url}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleItemClick(url);
                          }}
                          sx={{
                            color: '#000',
                            textDecoration: 'none',
                          }}
                        >
                          {typeof itemTextOrComponent === 'string'
                            ? t(itemTextOrComponent)
                            : itemTextOrComponent}
                        </Link>
                      </Grid>
                    )
                  );
                },
              )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
export default HeaderMenuLinksSubmenuMobile;
